<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getMedicalCertificatesReport"
  :isLoading="isLoading"
  :saveReport="getReportCertificatesMedicalExcel"
)
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'number',
          text: this.$t('tableHeaders.number'),
          sortable: false },
        { value: 'sailorFullName',
          text: this.$t('tableHeaders.sailor_full_name'),
          sortable: false },
        { value: 'date_start',
          text: this.$t('tableHeaders.date_issued'),
          sortable: false },
        { value: 'date_end',
          text: this.$t('tableHeaders.date_end'),
          sortable: false },
        { value: 'medical_institution',
          text: this.$t('tableHeaders.medical_institution'),
          sortable: false },
        { value: 'medicalPosition',
          text: this.$t('tableHeaders.position'),
          sortable: false },
        { value: 'status_document',
          text: this.$t('tableHeaders.status_document'),
          sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.medical_documents,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getMedicalCertificatesReport', 'getReportCertificatesMedicalExcel'])
  }
}
</script>
